<template>
  <div class="billDetail">
        
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="bnts"
                @printJieZhangClick="print('结账')"  
                @printXiaoFeiClick="print('消费')"
                @editOrderTaiTouClick="BillMenuClick('ModifySaleInfo')"
                @refCheckOutClick="BillMenuClick('RefCheckOut')"
                @tuicaiClick="BillMenuClick('RefQuit')"
                @chonghongClick="BillMenuClick('RubBackCheckOut')"
                @orderTrackClick="BillMenuClick('OrderUpdateLog')"
                @goBackClick="billBack()"/>
        </div>
        <span class="tag-red" v-if="billInfo.Is_RedBill">红冲</span>
        <div class="panel-box top-info-box">
           <el-row>
            <el-col :xs="12" :sm="8" :md="6" :lg="6">
                <span class="lable-text">结账单号：{{this.$global.isLableData?billInfo.NEWNO:billInfo.Eat_CheckIndex}}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6">
                 <span class="lable-text">结账人：{{billInfo.Eat_CheckCzy}}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">结账时间：{{ formatter(billInfo.Eat_CheckTime) }}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                <el-tooltip  effect="dark" :content="billInfo.Eat_DeskName" placement="bottom">
                    <span class="lable-text">台号：{{billInfo.Eat_DeskName}}</span>
                </el-tooltip>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">订单渠道：{{billInfo.CHANNEL_NAME}}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">消费类型：{{billInfo.Consume_TypeName}}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">人数：{{billInfo.Eat_ManNum}}人</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">席数：{{billInfo.Feast_DeskNum}}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">餐厅：{{billInfo.EAT_ROOMNAME}}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">会员卡号：{{$global.phonePrivacy(billInfo.Member_CardNo)}}</span>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="6"  >
                 <span class="lable-text">会员姓名：{{billInfo.Cstm_Name}}</span>
            </el-col>
          </el-row>
        </div>
        <div class="panel-box table-box">
            <el-table ref="tableEl" :data="billInfo.Order_ConsumeList"  style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                <el-table-column fixed="left" prop="" label="标记" width="85">
                    <template #default="scope">
                        <span class="tag tag-give" v-if="scope.row.IS_Gift">赠</span>
                        <span class="tag tag-retreat" v-if="scope.row.IS_Refund">退</span>
                        <span class="tag tag-discount" v-if="scope.row.IS_ZK">折</span>
                        <span class="tag tag-meeting" v-if="scope.row.IS_Member">会</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="left" prop="EAT_XFCode" label="菜品编码" width="110" align="left" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="left" prop="EAT_XFName" label="菜品名名称" width="140" align="left" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="left" prop="Eat_XFSize" label="单位" width="52" show-overflow-tooltip> </el-table-column>
                <el-table-column label="数量">
                    <el-table-column prop="Eat_Number" label="点单数量" min-width="75" align="right"></el-table-column>
                    <el-table-column prop="Refund_Number" label="退菜数量" min-width="75" align="right"></el-table-column>
                    <el-table-column prop="Gift_Number" label="赠菜数量" min-width="75" align="right"></el-table-column>
                </el-table-column>
                <el-table-column label="单价">
                    <el-table-column prop="Eat_SalePrice" label="原价" min-width="50" align="right" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Eat_CheckPrice" label="售价" min-width="50" align="right" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Eat_tcAVEPrice" label="套餐均价" min-width="75" align="right" :formatter="priceFormat"></el-table-column>
                </el-table-column>
                <el-table-column label="金额">
                    <el-table-column prop="Eat_SaleMoney" label="折前金额" min-width="75" align="right"></el-table-column>
                    <el-table-column prop="Eat_CheckMoney" label="结算金额" min-width="75" align="right"></el-table-column>
                </el-table-column>
                <el-table-column label="时间">
                    <el-table-column prop="Eat_Time" label="点单" min-width="65" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column prop="Eat_CrossTime" label="出品" min-width="65" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column prop="Eat_RefundTime" label="退菜" min-width="65" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column prop="Eat_GiftTime" label="赠送" min-width="65" :formatter="shortDateFormat"></el-table-column>
                </el-table-column>
                <el-table-column label="人员">
                    <el-table-column prop="Eat_Czy" label="点菜人" min-width="70" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="Eat_CookerName" label="厨师" min-width="70" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="Eat_RefundCzy" label="退菜人" min-width="78" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="Eat_GiftCzy" label="赠送人" min-width="78" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="Eat_ChangeCzy" label="改价人" min-width="78" show-overflow-tooltip></el-table-column>
                </el-table-column>
                <el-table-column label="操作原因">
                    <el-table-column prop="Refund_Reason" label="退菜原因" min-width="100">
                        <template #default="scope">
                            <el-tooltip effect="dark" :content="scope.row.Refund_Reason" placement="top">
                                <div>{{ scope.row.Refund_Reason }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Gift_Reason" label="赠送原因" min-width="100">
                        <template #default="scope">
                            <el-tooltip effect="dark" :content="scope.row.Gift_Reason" placement="top">
                                <div>{{ scope.row.Gift_Reason }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Change_Reason" label="改价原因" min-width="100">
                        <template #default="scope">
                            <el-tooltip effect="dark" :content="scope.row.Change_Reason" placement="top">
                                <div>{{ scope.row.Change_Reason }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="Eat_MasName" label="要求" width="110">
                    <template #default="scope">
                        <el-tooltip effect="dark" :content="scope.row.Eat_MasName" placement="top">
                            <div>{{ scope.row.Eat_MasName }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="panel-box total-info">
            <el-row >
                <el-col :xs="12" :sm="12">
                    <span class="lable-text">尾数调整：{{billInfo.Mantissa_Money}}</span>
                    <span class="lable-text">服务费：{{billInfo.Service_Money}}</span>
                </el-col>
                <el-col :xs="12" :sm="12" class="text-right">
                    <span v-if="RunningShow==true" class="invotext" :class="{selected:invoice==false}">发票&nbsp;:&nbsp;<el-switch v-model="invoice" active-color="#13ce66"></el-switch></span>
                    <span class="lable-text">小计：折前金额 {{billInfo.Eat_SaleMoney}}</span>
                    <span class="lable-text">结算金额 <span class="maney"> {{billInfo.Eat_CheckMoney}}</span></span>
                </el-col>
            </el-row>
        </div>
        <div class="panel-box payment-info">
            <div class="title">
                <span class="lable-text">付款明细</span>
                <span class="total">小计：<span class="total-money">{{paymentMoney}}</span></span>
            </div>
            <div class="payment-list">
                <el-row>
                    <el-col :xs="8" :sm="8" v-for="item in (billInfo.Order_PaymentDetailList||[])" :key="item">
                        <div class="lable-text">
                            <span class="Onespan">{{item.Payment_Name}}：{{item.Payment_Money}}
                            <div v-if="DailyEndBills && !billInfo.Is_RedBill && (item.Payment_Type==1 ||item.Payment_Type==2) " class="xiugai" @click="editPaymentShow(item)"><span class="iconfont icon-xiugai"></span></div>
                            </span>
                            <span class="Twospan">{{item.Payment_Remark}}</span>
                        </div>
                    </el-col>
                    <el-col :xs="8" :sm="8" v-for="item in orderPaymentNum" :key="item">
                        <div class="lable-text"></div>
                    </el-col>
                </el-row>
            </div>
        </div>
        
        <!-- 结账恢复操作 -->
        <modal-load :isShow="reccoveryIshow">
            <check-out-recovery-model :isShow="reccoveryIshow" v-on:closeModel="reccoveryIshow=false" v-on:confirm="checkOutRecovery"
                :data="{EAT_ROOMNAME:billInfo.EAT_ROOMNAME,Rpt_Date:formatter(billInfo.Rpt_Date,'yyyy-MM-dd'),Eat_CheckIndex:billInfo.Eat_CheckIndex}"
            ></check-out-recovery-model>
        </modal-load>
         <!-- 红冲 -->
        <modal-load :isShow="redReccoveryIshow">
            <check-out-recovery-model :isShow="redReccoveryIshow" v-on:closeModel="redReccoveryIshow=false" v-on:confirm="redBill" :isRedBill="true"
                :data="{EAT_ROOMNAME:billInfo.EAT_ROOMNAME,Rpt_Date:formatter(billInfo.Rpt_Date,'yyyy-MM-dd'),Eat_CheckIndex:billInfo.Eat_CheckIndex}"
            ></check-out-recovery-model>
        </modal-load>
        <!-- 退菜操作 -->
        <modal-load :isShow="returnDishesIshow">
        <return-dishes-model :isShow="returnDishesIshow"  v-on:closeModel="returnDishesIshow=false" :billInfo="billInfo" v-on:confirm="returnDishes"></return-dishes-model>
        </modal-load>
        <!-- 修改付款方式 -->
        <modal-load :isShow="eidtPaymentIshow">
            <eidt-payment-method-model :isShow="eidtPaymentIshow" :data="eidtPaymentData" @closeModel="eidtPaymentIshow=false" @confirm="eidtPaymentMethod"></eidt-payment-method-model>
        </modal-load>
        <!--修改账单联台选择-->
        <modal-load :isShow="unionIsshow">
            <union-select-model  v-on:closeModel="unionIsshow=false" :isShow="unionIsshow" :billInfo="billInfo" @unionConfirm="unionConfirm"></union-select-model>
        </modal-load>
        <!--修改账单抬头-->
        <modal-load :isShow="openIshow" :isUnload="true">
            <opening :opentype="2" v-on:CloseClick="openIshow=false" :orderType="2" :checkedOut="true" :orderInfo="deskPitch" :openisShow="openIshow" :openDeskName="deskPitch.Eat_DeskName" @updateCardClick="updateCardClick" ></opening>
        </modal-load>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
        <!--订单轨迹-->
        <modal-load :isShow="trackShow">
            <order-track-model :isShow="trackShow" v-on:closeModel="trackShow=false" :orderInfo="deskPitch"></order-track-model>
        </modal-load>
  </div>
</template>

<script>
import checkOutRecoveryModel from './checkOutRecoveryModel/checkOutRecoveryModel.vue';
import EidtPaymentMethodModel from './eidtPaymentMethodModel/eidtPaymentMethodModel.vue';
import ReturnDishesModel from './returnDishesModel/returnDishesModel.vue';
import opening from '../../diningTable/model/opening.vue'
import checkUserButtonModel from '../../checkUserRightModel/checkUserButtonModel.vue'
import unionSelectModel from './unionSelectModel/unionSelectModel.vue';
import OrderTrackModel from './orderTrackModel/orderTrackModel.vue';
import {cacheData} from '../../../../services';

/**全局初始化数据 */
let _data={
    RunAllowedChangeDailyEndBills:false, /**允许修改日结后的账单 */
    AllowChangeDailyEndBillsFromDays:0, /**允许修改近N天内(小于等于)的历史账单(不填或0,则不控制,返回-1则认为不能修改历史订单) */
    LimitedDaysOfReprintCheckoutBills:0 /**允许补打N天内(小于等于)的结账单(0则不控制) */

};

export default {
  components: { checkOutRecoveryModel, ReturnDishesModel, EidtPaymentMethodModel, checkUserButtonModel,unionSelectModel,opening,OrderTrackModel},
    name:'billDetail',
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
          _data.RunAllowedChangeDailyEndBills = globalSysSetting.RunAllowedChangeDailyEndBills;
          _data.AllowChangeDailyEndBillsFromDays = globalSysSetting.AllowChangeDailyEndBillsFromDays;
          _data.Running_InvoicePintByChosen_YN = globalSysSetting.Running_InvoicePintByChosen_YN;
          _data.OpenCashierHandInvoiceWithDefaultNotIssueInovoice=globalSysSetting.OpenCashierHandInvoiceWithDefaultNotIssueInovoice;
          _data.LimitedDaysOfReprintCheckoutBills = globalSysSetting.LimitedDaysOfReprintCheckoutBills;
        }
        return {
            /**账单数据 */
            billInfo:{},
            /**修改账单抬头是否显示弹层 */
            openIshow:false,
            /** 结账恢复 是否显示弹层*/
            reccoveryIshow:false,
            /**修改账单联台是否显示弹层 */
            unionIsshow:false,
            /** 红冲 */
            redReccoveryIshow:false,
            /** 退菜 是否显示弹层*/
            returnDishesIshow:false,
            /**修改付款方式 是否显示弹层*/
            eidtPaymentIshow:false,
            /**修改付款方式 需要传入的数据 */
            eidtPaymentData:{},
            /**付款明细 金额 */
            paymentMoney:0,
            /**订单操作时间戳 操作订单需要 */
            timestamps:'',
            /**是否是历史单据 */
            isOldBill:true,
            /**界面按钮 */
            billMenu:[],
            /**权限 是否显示弹层 */
            checkShow:false,
            checkBtnlist:'',
            /**是否允许修改历史账单 */
            DailyEndBills:false,
            /**是否联台 */
            UnionPrint:false,
            /**付款方式 */
            ModifyPayInfo:'',
            PaymentInfo:'',
            checkBtnType:0,
            /**结账方式数据 */
            payTypeList:[],
            invoice:true,  /**发票是否打印 */
            RunningShow:false, //是否显示发票打印按钮
            /**快餐订单 */
            abnormalInfo:null,
            /**选中的桌台 */
            deskPitch:'',

            trackShow:false,
            uniontype:0,
            DailyCheckoutBills:true
        }
    },
    computed:{
        /**付款明细 样式 填充 */
        orderPaymentNum(){
            let length=(this.billInfo.Order_PaymentDetailList||[]).length;
            if(length<6){
                return 6-length;
            }
            if(length%3!=0){
                return 3-(length%3);
            }
            return 0;
        },
        /**按钮 */
        bnts(){
            let bnts=['goBack'];
            if(this.DailyCheckoutBills){
                bnts.push('printJieZhang')//修改账单抬头
            }
            if(this.billMenu?.some(it=>it.ProjectItem_Key=='OrderUpdateLog')){
                bnts.push("orderTrack");//订单轨迹
            }

            if(this.billInfo?.Order_Type!=1 && this.billInfo?.Order_Type!=2 && this.billInfo?.Order_Type!=3){
                bnts.push('printXiaoFei');//重印消费单
            }
            if(this.DailyEndBills && !this.billInfo?.Is_RedBill){
                bnts.push('editOrderTaiTou')//修改账单抬头
                if(this.billInfo?.Order_Type!=3){
                    bnts.push('refCheckOut')//结账恢复
                }
            }
            if((!this.billInfo?.Is_HisOrder && !this.billInfo?.Is_RedBill) || ((this.billInfo?.Order_Type==1 || this.billInfo?.Order_Type==2 || this.billInfo?.Order_Type==3) && this.DailyEndBills)){
                bnts.push('tuicai');//退菜
                bnts.push('chonghong');//冲红
            }
            return bnts;
        }
    },
    watch:{
        invoice(newVal){
            this.$global.invoiceOff=!newVal;
        }
    },
    mounted(){
        this.$emit("navIndex",1);
        
        this.$nextTick(()=>{
            console.log(_data.OpenCashierHandInvoiceWithDefaultNotIssueInovoice)
            if(_data.Running_InvoicePintByChosen_YN){
                if(_data.OpenCashierHandInvoiceWithDefaultNotIssueInovoice){
                    this.$global.invoiceOff=true;
                }
                this.invoice=!this.$global.invoiceOff;
                this.RunningShow=true;
            }else{//默认false 打印发票
                this.$global.invoiceOff=false;
                this.invoice=!this.$global.invoiceOff;
            }
            this.loadDetailData(this.$route.params.id,this.$route.params.Rpt_Date);
        });
    
        /**自定义功能按钮 */
        let SysProjects=this.$cacheData.SysProjects.get();
        if(SysProjects && SysProjects.length>0){
            SysProjects.forEach(element=> {
            if(element.Project_Key=='Bill'){
                element.SysProjectGroups.forEach(project=>{
                    /**账单自定义按钮 */
                    if(project.Group_Key=='Bill-Top'){
                        project.SysProjectItems.forEach((item)=>{
                            let Menu=this.billMenu[item.ProjectItem_Key];
                            if(Menu==null ||Menu=='undefined'){
                                if(project.OtherFunc_YN==false){
                                    if(project.OtherFunc_Key!=item.ProjectItem_Key){
                                        this.billMenu.push(item);
                                    }
                                }else{
                                    console.log(item);
                                    this.billMenu.push(item);
                                }
                            }
                        })
                    }
                })
            }
            if(element.Project_Key=='Order'){
                element.SysProjectGroups.forEach(project=>{
                    /**桌台界面自定义按钮 */
                    if(project.Group_Key=='OrderMain-Middle'){
                        project.SysProjectItems.forEach((item)=>{
                            if(item.ProjectItem_Key=='OtherFunc'){
                                item.SysProjectSubItems.forEach((sys)=>{
                                    if(sys.ProjectSubItem_Key=='OrderUpdateLog')
                                    {
                                        console.log(sys);
                                        this.billMenu.push({
                                            Is_Show:sys.Is_Show,
                                            Permission:sys.Permission,
                                            ProjectItem_Index:sys.ProjectSubItem_Index,
                                            ProjectItem_Key:sys.ProjectSubItem_Key,
                                            ProjectItem_Name:sys.ProjectSubItem_Name,
                                            ProjectItem_ShortcutKey:sys.ProjectSubItem_ShortcutKey,
                                            ProjectItem_Type:sys.ProjectSubItem_Type
                                            
                                        });
                                    }
                                })
                            }
                        })
                    }
                })
            }
            });
            this.billMenu.map(bill=>{
                if(bill.ProjectItem_Key=='ModifyPayInfo'){
                    this.ModifyPayInfo = bill
                }
            })
        }
        /**获取支付方式 */
        this.$cacheData.PayType().then((d)=>{
            if(d){
                let all=[]
                d.forEach(item => {
                    item.PayTypes.forEach((type)=>{
                        all.push(type)
                    })
                });
                this.payTypeList=all;
            }
        }).catch((e)=>{
            console.log('加载支付方式数据失败：'+e);
        })
    },
    methods:{
        /**加载明细数据 @param id 订单id*/
        loadDetailData(id,Rpt_Date){
            if(!id || id==''){
                this.$alert('账单id错误', '提示', {
                    confirmButtonText: '确定',
                    callback: (name) => {
                        if(name=='confirm'){
                            this.$router.back(); 
                        }
                    }
                });
                return ;
            }
            let userInfo= this.$auth.getUserInfo();
            if(userInfo && new Date(userInfo.Rpt_Date).Format('yyyy-MM-dd')==new Date(Rpt_Date).Format('yyyy-MM-dd')){//营业日期 当天
                this.isOldBill=false;
            }
            
            let dateNumber = this.difference(new Date(Rpt_Date),new Date(userInfo.Rpt_Date))
            
            if(_data.RunAllowedChangeDailyEndBills){
                if(_data.AllowChangeDailyEndBillsFromDays==0 || (dateNumber-1) <=_data.AllowChangeDailyEndBillsFromDays){
                    this.DailyEndBills = true
                }
                if(_data.AllowChangeDailyEndBillsFromDays==-1){
                    this.DailyEndBills = false
                }
            }
            if(_data.LimitedDaysOfReprintCheckoutBills){
                if(_data.LimitedDaysOfReprintCheckoutBills==0 || (dateNumber-1) <=_data.LimitedDaysOfReprintCheckoutBills){
                    this.DailyCheckoutBills = true
                }
                else
                {
                    if(typeof(_data.LimitedDaysOfReprintCheckoutBills)=='undefined')
                    {
                        this.DailyCheckoutBills = true;
                    }
                    else{
                        this.DailyCheckoutBills = false;
                    }
                }
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetBillDetail",{
                Eat_AutoID:id,
                User_ID:this.$auth.getUserID(),
                Rpt_Date:new Date(Rpt_Date).Format('yyyy-MM-dd')
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    let info=d.ResponseBody||{};
                    this.timestamps=d.ResponseBody.Timestamps;
                    if(info.Eat_State==1){
                        this.$router.push({name: 'diningDetail', params:{Desk_AutoID:info.Eat_DeskID,Eat_AutoID:info.Eat_AutoID,urlType:0}});                        
                    }else{
                        this.initOrderInfo(info);
                    }
                }else{
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: (name) => {
                            if(name=='confirm'){
                                this.$router.back(); 
                            }
                        }
                    });
                }
            }).catch((e)=>{
                loading.close();
                this.$alert('加载账单数据失败：'+e, '提示', {
                    confirmButtonText: '确定',
                    callback: (name) => {
                        if(name=='confirm'){
                            this.$router.back(); 
                        }
                    }
                });
                console.log('加载账单数据失败：'+e);
            })
        },
        initOrderInfo(info){
            if(info?.Cstm_Name){
                info.Member_Name=info?.Cstm_Name;//会员名称
            }
            this.billInfo=info;
            if(this.billInfo.Is_HisOrder==false){
                this.DailyEndBills = true
            }
            let money=0;
            (info.Order_PaymentDetailList||[]).forEach(item => {
                money+=item.Payment_Money;
            });
            this.paymentMoney=parseFloat(money.toFixed(4));
            /**判断是否联台 */
            if(this.billInfo.Eat_ID.indexOf(',') !=-1){
                this.UnionPrint = true
            }
            /**判断订单支付类型 */
            // this.billInfo.Order_PaymentDetailList.map(pay=>{
            //     this.payTypeList.map(p=>{
            //         if(pay.Payment_ID==p.PAY_ID){
            //             pay = Object.assign(pay,{INTERFACE_TYPE:p.INTERFACE_TYPE})
            //         }
            //     })
            // })
            // console.log('billInfo:'+JSON.stringify(this.billInfo))
            // console.log('payTypeList:'+JSON.stringify(this.payTypeList))
        },
        /**菜单按钮事件控制 */
        BillMenuClick(ProKey){
            console.log(ProKey)
            this.billMenu.map(bill=>{
                if(bill.ProjectItem_Key==ProKey){
                    if(bill.Permission==false){
                        this.$confirm('该账户权限不足,不支持'+bill.ProjectItem_Name+'功能', '提示', {
                            confirmButtonText: '去验证',
                            cancelButtonText: '我知道了',
                            type: 'warning'})
                        .then(() => {
                            this.checkBtnlist = JSON.parse(JSON.stringify(bill))
                            this.checkBtnType=2
                            this.checkShow = true
                            this.$keyavail=true
                        }).catch(()=>{});
                        return;
                    }else{
                        if(ProKey=='ModifySaleInfo'){
                            if(this.billInfo?.OrderTitles && this.billInfo?.OrderTitles.length>=2){
                                this.uniontype=0;
                                this.unionIsshow=true
                            }else{
                                this.deskAssignMethod()
                                this.openIshow=true
                            }
                        }
                        if(ProKey=='RefCheckOut'){
                            this.reccoveryIshow=true
                        }
                        if(ProKey=='RefQuit'){
                            this.returnDishesIshow=true
                        }
                        if(ProKey=='RubBackCheckOut'){
                            this.redReccoveryIshow=true
                        }
                        if(ProKey=='OrderUpdateLog'){
                            if(this.billInfo?.OrderTitles && this.billInfo?.OrderTitles.length>=2){
                                this.uniontype=1;
                                this.unionIsshow=true
                            }else{
                                let _desk = {
                                    Eat_AutoID:this.billInfo?.Eat_AutoID,
                                    Rpt_Date:this.billInfo?.Rpt_Date
                                }
                                this.deskPitch=_desk;
                                this.trackShow=true
                            }
                        }
                    }
                }
            })
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        formatter(cellValue,format) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format(format||'yyyy-MM-dd hh:mm');
        },
        /*时间格式 时分*/
        shortDateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('hh:mm');
        },
        /**确认结账恢复 @param item 原因 {Is_RefundLoss: 是否有损退菜,RefundReason_AutoID: "id",RefundReason_Code: "编号",RefundReason_Name: "名称"}*/
        checkOutRecovery(item){
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                Operator_Name:userInfo?.Login_Name,
                Eat_AutoID:this.billInfo.Eat_AutoID,
                Rpt_Date:this.billInfo.Rpt_Date,
                ReverseCheckOut_Reason:item.RefundReason_Name,
                User_ID:userInfo?.User_ID,
                Timestamps:this.timestamps
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$httpAES.post("Bestech.CloudPos.ReverseCheckOut",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.timestamps=d.ResponseBody.Timestamps;
                    this.reccoveryIshow=false;
                    this.$alert('结账恢复成功', '提示', {
                        confirmButtonText: '确定',
                        callback: (name) => {
                            if(name=='confirm'){
                                //快餐
                                if(this.billInfo?.Order_Type==1){
                                    this.getFastOrderConsume(d.ResponseBody?.Eat_AutoID)
                                }else{
                                    console.log(this.billInfo,d.ResponseBody)
                                    if(d.ResponseBody?.Eat_DeskID){
                                        this.$router.push({
                                            name: 'diningDetail',
                                            params:{
                                                Desk_AutoID:d.ResponseBody?.Eat_DeskID,//桌台id
                                                Eat_AutoID:this.billInfo.Eat_AutoID,//订单id
                                                Eat_RoomID:this.billInfo.Eat_RoomID,//门店id
                                                urlType:0
                                            }
                                        });
                                    }else{
                                        this.$router.push({
                                            name: 'diningDetail', 
                                            params:{
                                                Desk_AutoID:this.billInfo.Eat_DeskID,
                                                Eat_AutoID:this.billInfo.Eat_AutoID,
                                                Eat_RoomID:this.billInfo.Eat_RoomID,//门店id
                                                urlType:0
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    });
                }else{
                    this.$message.error("结账恢复失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('结账恢复失败：'+e);
                console.log('结账恢复失败：'+e);
            })
        },
        /**获取快餐订单 */
        getFastOrderConsume(aotoID){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            /**OrderKind 订单类型 1外卖订单 2异常订单 */
            this.$httpAES.post("Bestech.CloudPos.GetSpecialOrderConsume",{
                OrderKind:2,
                OrderAutoid:aotoID,
                User_ID:this.$auth.getUserID()
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.abnormalInfo=d.ResponseBody||{};
                    this.$router.push({name: 'fastFoodDetail', params:{data:JSON.stringify(this.abnormalInfo)}}); 
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载外卖订单数据失败：'+e.message);
            })
        },
        /**确认 退菜*/
        returnDishes(info){
            if(info){
                const loading = this.$loading({
                    text: "请求操作中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let userInfo= this.$auth.getUserInfo();
                let param={
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Pos_StationName:userInfo?.Site_Name,
                    Eat_AutoID:this.billInfo.Eat_AutoID,
                    Rpt_Date:this.billInfo.Rpt_Date,
                    Eat_CheckIndex:this.billInfo.Eat_CheckIndex,
                    Refund_TotalMoney:info.Refund_TotalMoney,//退单总金额
                    Refund_Reason:info.Refund_Reason,//退单原因
                    RefundOrderConsumes:info.RefundOrderConsumes,//退单菜品
                    RefundOrderPayments:info.RefundOrderPayments,//退单支付金额
                    Print_YN:info.Print_YN,//是否打印结账单
                    Timestamps:this.timestamps
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                this.$httpAES.post("Bestech.CloudPos.RefundBill",param).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.$message.success("退菜成功");
                        this.timestamps=d.ResponseBody?.BillInfo?.Timestamps;
                        this.initOrderInfo(d.ResponseBody.BillInfo);
                        this.returnDishesIshow=false;
                        if(info.Print_YN && d.ResponseBody.PrintsInfo){
                            console.log("退菜打印数据",d.ResponseBody.PrintsInfo)
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                            this.$message.success("已发送打印指令");
                        }
                    }else{
                        this.$message.error("退菜失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('退菜失败：'+e.message);
                    console.log('退菜失败：'+e);
                })
            }
        },
        /**红冲 */
        redBill(item){
            if(item){
                const loading = this.$loading({
                    text: "请求操作中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let userInfo= this.$auth.getUserInfo();
                let param={
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Pos_StationName:userInfo?.Site_Name,
                    Eat_AutoID:this.billInfo.Eat_AutoID,
                    Rpt_Date:this.billInfo.Rpt_Date,
                    Eat_CheckIndex:this.billInfo.Eat_CheckIndex,
                    Refund_Reason:item.RefundReason_Name,//退单原因
                    Print_YN:item.Print_YN,//是否打印
                    Timestamps:this.timestamps
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                this.$httpAES.post("Bestech.CloudPos.RedBill",param).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.$message.success("红冲成功");
                        this.timestamps=d.ResponseBody?.BillInfo?.Timestamps;
                        this.initOrderInfo(d.ResponseBody.BillInfo);
                        this.redReccoveryIshow=false;
                        if(param.Print_YN && d.ResponseBody.PrintsInfo){
                            console.log("红冲并打印数据",d.ResponseBody.PrintsInfo)
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                            this.$message.success("已发送打印指令");
                        }
                    }else{
                        this.$message.error("红冲失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('红冲失败：'+e);
                    console.log('红冲失败：'+e);
                })
            }
        },
        /**显示 修改付款饭上 */
        editPaymentShow(item){
            this.PaymentInfo = item
            if(this.ModifyPayInfo){
                if(this.ModifyPayInfo.Permission==false){
                    this.$confirm('该账户权限不足,不支持'+this.ModifyPayInfo.ProjectItem_Name+'功能', '提示', {
                        confirmButtonText: '去验证',
                        cancelButtonText: '我知道了',
                        type: 'warning'})
                    .then(() => {
                        this.checkBtnType=1
                        this.checkShow = true
                        this.checkBtnlist = this.ModifyPayInfo
                    }).catch(()=>{});
                    return;
                }
            }
            this.eidtPaymentData={
                DeskName:this.billInfo.Eat_DeskName,
                CheckMoney:item.Payment_Money,
                PaymentID:item.Payment_ID,
                PaymentName:item.Payment_Name
            }
            this.eidtPaymentData._paymentInfo=item;
            this.eidtPaymentIshow=true;
        },
        /**确认 付款方式 */
        eidtPaymentMethod(paymentInfo){
            if(paymentInfo){
                const loading = this.$loading({
                    text: "请求操作中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let userInfo= this.$auth.getUserInfo();
                let param={
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Rpt_Date:this.billInfo.Rpt_Date,//单据营业日期
                    Eat_PayDetailID:this.eidtPaymentData?._paymentInfo?.Eat_PayDetailID,
                    Payment_ID:paymentInfo.PaymentID,
                    Timestamps:this.timestamps
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                this.$httpAES.post("Bestech.CloudPos.ModifyPayment",param).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.$message.success("付款方式修改成功");
                        this.timestamps=d.ResponseBody.Timestamps;
                        this.eidtPaymentData._paymentInfo.Payment_ID=paymentInfo.PaymentID;
                        this.eidtPaymentData._paymentInfo.Payment_Name=paymentInfo.PaymentName;
                        this.eidtPaymentData._paymentInfo.Payment_Code=paymentInfo.Payment_Code;
                        this.eidtPaymentIshow=false;
                        this.billInfo.Timestamps=d.ResponseBody.Timestamps;
                    }else{
                        this.$message.error("修改支付方式失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('修改支付方式失败：'+e);
                    console.log('结账恢复失败：'+e);
                })
            }
        },
        /**打印 */
        print(type){
            let message="结账单";
            let printType=2;// 重印结账单
            if(type=="消费"){//重印消费单
                printType=1;
                message="消费单";
            }
            const loading = this.$loading({
                text: "请求"+message+"打印数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                UserID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:this.billInfo.Rpt_Date,
                /**业务， 1：触摸屏POS   2：扫码点单   3：外卖 */
                PrintBusiness:this.billInfo.Order_Source,
                /**打印类型， 打印类型， 
                 * 1	消费清单 2	结账清单 8	客单单 9	结班单 10	日结单 88	加菜单
                 */
                PrintType:printType,
                Eat_CheckIndex:this.billInfo.Eat_CheckIndex,
                Order_ID:this.billInfo.Eat_AutoID,
                Is_CheckedOut:true,
                ElectronicInvoice_PrintForbidden:false,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
            }
            if(printType==2){ //结账清单
                param.ElectronicInvoice_PrintForbidden = this.$global.invoiceOff;
            }
            if(this.UnionPrint==true){
                param = Object.assign(param,{Is_UnionPrint:true})
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$httpAES.post("Bestech.CloudPos.GetPrintContent",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    console.log(d)
                    this.$webBrowser.posPrint(d.ResponseBody);
                    this.$message.success("已发送"+message+"打印指令");
                }else{
                    this.$message.error("请求"+message+"打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求'+message+'打印数据失败：'+e);
                console.log('请求'+message+'打印数据失败：'+e);
            })
        },
         /**修改账单抬头信息返回 */
        updateCardClick(data){
            this.openIshow=false;
            if(data.ResponseHeader.ResultCode==0){
                this.loadDetailData(this.$route.params.id,this.$route.params.Rpt_Date);
            }else{
                this.$message.error('账单抬头修改失败:'+data.ResponseHeader.ResultDesc);
            }
        },
        /**订单里桌台数据 */
        deskAssignMethod(){
            let _desk = {
                Eat_AutoID:this.billInfo?.OrderTitles[0].Eat_AutoID,
                Eat_DeskName:this.billInfo?.OrderTitles[0].Eat_DeskName,
                Eat_ManNum:this.billInfo?.OrderTitles[0].Eat_PersonNum,
                Feast_DeskNum:this.billInfo?.OrderTitles[0].Feast_DeskNum,
                Member_CardNo:this.billInfo?.OrderTitles[0].Member_CardNo,
                Member_Name:this.billInfo?.OrderTitles[0].Member_Name,
                Mobile:this.billInfo?.OrderTitles[0].Mobile,
                Member_CardKind:this.billInfo?.OrderTitles[0].Member_CardKind,
                Consume_TypeID:this.billInfo?.OrderTitles[0].Consume_TypeID,
                CHANNEL_ID:this.billInfo?.OrderTitles[0].CHANNEL_ID,
                Eat_WaiterName:this.billInfo?.OrderTitles[0].Eat_Waiter,
                Eat_Saler:this.billInfo?.OrderTitles[0].Eat_Saler,
                Order_Remark:this.billInfo?.OrderTitles[0].Order_Remark,
                Rpt_Date:this.billInfo?.OrderTitles[0]?.Rpt_Date,
                Timestamps:this.billInfo?.OrderTitles[0]?.Timestamps,
                Is_ReserveOrder:this.billInfo?.OrderTitles[0]?.Is_ReserveOrder,//是否预订开台
            }
            this.deskPitch=_desk
        },
        /**权限控制 */
        checkbtnReturn(date){
            this.checkShow = false;
            sessionStorage.setItem("tempUserID",date.User_ID)
            sessionStorage.setItem("tempName",date.Login_Name)
            if(this.checkBtnType==1){
                this.ModifyPayInfo.Permission = true;
                this.editPaymentShow(this.PaymentInfo)
            }else{
                if(this.checkBtnlist.ProjectItem_Key=='ModifySaleInfo'){
                    if(this.billInfo?.OrderTitles && this.billInfo?.OrderTitles.length>=2){
                        this.uniontype=0;
                        this.unionIsshow=true
                    }else{
                        this.deskAssignMethod()
                        this.openIshow=true
                    }
                }
                if(this.checkBtnlist.ProjectItem_Key=='RefCheckOut'){
                    this.reccoveryIshow=true
                }
                if(this.checkBtnlist.ProjectItem_Key=='RefQuit'){
                    this.returnDishesIshow=true
                }
                if(this.checkBtnlist.ProjectItem_Key=='RubBackCheckOut'){
                    this.redReccoveryIshow=true
                }
                if(this.checkBtnlist.ProjectItem_Key=='OrderUpdateLog'){
                    if(this.billInfo?.OrderTitles && this.billInfo?.OrderTitles.length>=2){
                        this.uniontype=1;
                        this.unionIsshow=true;
                    }else{
                        let _desk = {
                            Eat_AutoID:this.billInfo?.Eat_AutoID,
                            Rpt_Date:this.billInfo?.Rpt_Date
                        }
                        this.deskPitch=_desk;
                        this.trackShow=true
                    }
                }
            }
        },
        /**时间相差 */
        difference(dateBegin,dateEnd) {
            var dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数
            var dayDiff = Math.ceil(dateDiff / (24 * 3600 * 1000));//计算出相差天数
            return dayDiff;
        },
        /**返回 */
        billBack(){
            this.$router.push({
                path: 'bill',name:'bill',
                params:{
                    isNoRefresh:true//是否不刷新 用缓存数据
                }
            });
        },
        unionConfirm(desk){
            this.unionIsshow=false
            let _desk = {
                Eat_AutoID:desk.Eat_AutoID,
                Eat_DeskName:desk.Eat_DeskName,
                Eat_ManNum:desk.Eat_PersonNum,
                Feast_DeskNum:desk.Feast_DeskNum,
                Member_CardNo:desk.Member_CardNo,
                Member_Name:desk.Member_Name,
                Mobile:desk.Mobile,
                Member_CardKind:desk.Member_CardKind,
                Consume_TypeID:desk.Consume_TypeID,
                CHANNEL_ID:desk.CHANNEL_ID,
                Eat_WaiterName:desk.Eat_Waiter,
                Eat_Saler:desk.Eat_Saler,
                Order_Remark:desk.Order_Remark,
                Rpt_Date:desk?.Rpt_Date,
                Timestamps:desk?.Timestamps,
                Is_ReserveOrder:this.billInfo?.OrderTitles[0]?.Is_ReserveOrder,//是否预订开台
            }
            this.deskPitch=_desk;
            if(this.uniontype==0){
                this.openIshow=true
            }
            else{
                this.trackShow=true
            }
        },
    }
}
</script>

<style lang="scss">
    @import "./billDetail.scss"
</style>